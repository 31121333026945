.single-sport-page {
    color: #fff;

    .sportspage {
        // position: absolute;
        // top: 70px;
        // background: #151515;
        // border: 2px solid red;
        display: flex;
        margin-bottom: 20px;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        // height: 100%;

        .popup-content {
            width: 65%;
            // height: 10%;
            top: 60px;
            position: absolute;
            // left: -95px;
            display: flex;
            justify-content: center;
            // flex-direction: column;
            // background: #151515;
            border-radius: 10px;
            // border: 2px solid #ee5306;
            // color: #0a0027;
            // margin-bottom: 30px;
            text-align: center;
            align-items: center;

            .match-popup {
                width: 30%;
                display: flex;
                // width: 30%;
                align-items: center;
                justify-content: center;
                // margin-left: 35px;
                // border: 2px solid teal;

                .warm {
                    font-size: 28px;
                    // position: absolute;
                    cursor: pointer;
                    // top: 70px;
                    flex: 4;
                    color: #FF6D02;
                }

                .cold {
                    font-size: 28px;
                    // position: absolute;
                    cursor: pointer;
                    // top: 70px;
                    flex: 4;
                    color: #36d8fe;
                }

                .next {
                    font-size: 30px;
                    background-color: transparent;
                    cursor: pointer;
                    color: white;
                }


            }

            .match-liveScore {
                width: 20%;
                display: flex;
                align-items: center;
                justify-content: space-around;
                cursor: pointer;
                // border: 2px solid red;
                margin-right: 40px;
                // margin-top: 10px;

                .warm {
                    font-size: 24px;
                    // position: absolute;
                    cursor: pointer;
                    // top: 70px;
                    flex: 4;
                    color: #FF6D02;
                }

                .cold {
                    font-size: 24px;
                    // position: absolute;
                    cursor: pointer;
                    // top: 70px;
                    flex: 4;
                    color: #36d8fe;
                }

                .next {
                    font-size: 30px;
                    background-color: transparent;
                    cursor: pointer;
                    color: white;
                }
            }
        }

        .cards {
            display: flex;
            align-items: center;
            position: relative;
            top: 150px;
            // flex-direction: column;
            justify-content: center;
            // border: 2px solid red;
            // margin-top: 30px;
            // background-color: #151515;
            border-radius: 10px;
            height: 70%;
            width: 65%;
            color: white;
            margin-top: 20px;

            .sports-img {
                border: 2px solid #4c4b4b;
                height: 450px;
                border-radius: 20px;
                width: 460px;
                margin-top: 10px;
                // margin-right: 40px;

                img {
                    // border: 2px solid red;
                    object-fit: fill;
                    border-radius: 20px;
                    height: 100%;
                    width: 100%;
                }
            }

            .sports-info {
                // border: 2px solid blue;
                padding-top: 10px;
                height: 450px;
                width: 70%;
                margin-left: 40px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                // text-align: center;

                .warm {
                    color: #FF6D02;
                }

                .cold {
                    color: #36d8fe;
                }

                #warm {
                    margin-bottom: 10px;
                    color: #FF6D02;
                    font-size: 46px;
                    // border-bottom: 3px solid #FF6D02;
                }

                #cold {
                    margin-bottom: 10px;
                    font-size: 46px;
                    color: #36d8fe;
                    // border-bottom: 3px solid #36d8fe;
                }

                .venue {
                    font-size: 24px;
                }


                .desc {
                    width: 90%;
                    margin-bottom: 0px;
                    font-size: 24px;
                }

                .date {
                    // margin-bottom: 10px;
                    // font-weight: 700;
                    font-size: 24px;
                }

                .poc {
                    font-size: 24px;
                }

                .rulebook {
                    // text-decoration: none;
                    font-size: 24px;
                    color: white;
                    text-decoration: none;
                    // margin-bottom: 10px;
                }

                .register-btn {
                    border: none;
                    border-radius: 10px;
                    display: inline-block;
                    // margin: auto;
                    // margin-top: 20px;
                    height: 50px;
                    width: 50%;
                    background-color: #FF6D02;
                    font-size: 20px;
                    color: #fff;
                    cursor: pointer;

                    &:hover {
                        background-color: #FF6D02;
                        box-shadow: 2px 2px 5px #ff5602;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1150px) {
    .single-sport-page {
        // height: 100%;
        width: 100%;
        // background: #151515;
        // top: 184px;

        .sportspage {
            // border: 2px solid blue;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;
            // height: 150%;
            color: #141E46;

            .popup-content {
                width: 100%;
                // height: 100px;
                // background: #151515;
                flex-direction: column;
                // justify-content: space-evenly;
                border-radius: 0px;

                .match-popup {
                    width: 70%;

                }

                .match-liveScore {
                    width: 70%;
                    margin-right: 0px;
                }
            }

            .cards {
                display: flex;
                flex-direction: column-reverse;
                // background-color: #151515;
                align-items: center;
                // min-height: 120vh;
                width: 100%;
                color: white;
                margin-top: 0px !important;
                margin-bottom: 40px;
                padding: 0px 20px ;

                .sports-img {
                    width: 300px;
                    height: 300px;
                    border-radius: 20px;
                    margin-right: 0px;
                    margin: 10px 0;

                    img {
                        // border: 2px solid red;
                        object-fit: fill;
                        border-radius: 20px;
                        height: 100%;
                        width: 100%;
                    }
                }

                .sports-info {
                    // margin-top: 20px;
                    // border: 2px solid blue;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    width: 100%;
                    margin-left: 0px;
                    text-align: center;

                    .warm,
                    .cold {
                        text-align: center;
                        font-size: 20px;
                        font-weight: 900;
                        margin-bottom: 0px;
                    }
                    #warm,
                    #cold {
                        color: #FF6D02;
                        text-align: center;
                        font-size: 35px;
                        font-weight: 900;
                        margin-bottom: 5px;
                    }
                    #cold{
                        color: #36d8fe;
                    }

                    .desc {
                        margin-top: 5px;
                        margin-bottom: 10px;
                        text-align: center;
                        font-size: 20px;
                        width: 90%;
                        // border: 2px solid red;

                    }

                    .date {
                        font-size: 18px;
                    }

                    .poc {
                        font-size: 18px;
                    }

                    .venue,
                    .rulebook {
                        font-size: 18px;
                        color: white;
                    }

                    .register-btn {
                        margin-top: 5px;
                        height: 40px;

                        &:hover {
                            background-color: #f96807;
                            box-shadow: 2px 2px 5px #ee5306;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 350px) {
    .single-sport-page {
        height: 180%;
        width: 100%;
        background: #EADBC8;

        .sportspage {
            color: #141E46;
            // border: 2px solid blue;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;
            height: 150%;

            .popup-content {
                width: 100%;
                height: 100px;
                background: transparent;
                // background-color: #151515;


                .match-popup {
                    width: 100%;
                    font-size: 16px;

                    .warm {
                        text-align: center;

                        &:hover {
                            color: #ff5602;
                        }
                    }

                    .cold {
                        text-align: center;

                        &:hover {
                            color: #36d8fe;
                        }
                    }
                }

                .match-liveScore {
                    font-size: 16px;
                    margin-right: 0px;
                    width: 100%;
                }
            }

            .cards {
                display: flex;
                flex-direction: column-reverse;
                // border: 2px solid red;
                // justify-content: center
                margin-top: 40px;
                height: 70%;
                width: 100%;
                margin-top: 20px;

                .sports-img {
                    width: 80%;
                    height: 250px;
                    border-radius: 20px;
                    // border: 2px solid red;

                    img {
                        // border: 2px solid red;
                        object-fit: fill;
                        border-radius: 20px;
                        height: 100%;
                        width: 100%;
                    }
                }

                .sports-info {
                    // margin-top: 20px;
                    // border: 2px solid blue;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    margin-left: 0px;

                    .warm,
                    .cold {
                        text-align: center;
                        font-size: 24px;
                        margin-bottom: 0px;
                    }

                    .desc {
                        margin-top: 5px;
                        margin-bottom: 5px;
                        text-align: center;
                        font-size: 16px;
                        width: 100%;

                    }

                    .date {
                        font-size: 18px;
                    }

                    .poc {
                        font-size: 18px;
                    }

                    .register-btn {
                        margin-top: 5px;
                        height: 40px;

                        &:hover {
                            background-color: #f96807;
                            box-shadow: 2px 2px 5px #ee5306;
                        }
                    }
                }
            }
        }
    }
}