.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    // border: 2px solid red;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;

    .popup-inner {
        position: relative;
        width: 40%;
        height: 100%;
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
        backdrop-filter: blur(9px);
        -webkit-backdrop-filter: blur(10px);
        border: 1px solid rgba(255, 255, 255, 0.18);
        box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
        color: black;
        border: 2px solid rgb(180, 180, 180);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;

        .close-btn {
            position: absolute;
            top: 16px;
            right: 16px;
            background: transparent;
            color: #fff;
            border: none;
            font-size: 20px;

            .close {
                font-size: 40px;
                cursor: pointer;
            }
        }

        .images {
            width: 100%;
            height: 100%;
            border-radius: 20px;
        }
    }
}

@media only screen and (max-width: 450px) {
    .popup {
        position: fixed;
        border: 2px solid white;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        // border: 2px solid red;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;

        .popup-inner {
            position: relative;
            width: 100%;
            height: 100%;
            background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
            backdrop-filter: blur(9px);
            -webkit-backdrop-filter: blur(10px);
            border: 1px solid rgba(255, 255, 255, 0.18);
            box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
            color: black;
            border: 2px solid rgb(180, 180, 180);
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;

            .close-btn {
                position: absolute;
                top: 16px;
                right: 16px;
                background: transparent;
                color: #fff;
                border: none;
                font-size: 20px;

                .close {
                    font-size: 40px;
                    cursor: pointer;
                }
            }

            .images {
                width: 100%;
                height: 100%;
                border-radius: 0px;
            }
        }
    }
}

@media only screen and (max-width: 350px) {
    .popup {
        position: fixed;
        // border: 2px solid red;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        // border: 2px solid red;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;

        .popup-inner {
            position: relative;
            width: 100%;
            height: 100%;
            background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
            backdrop-filter: blur(9px);
            -webkit-backdrop-filter: blur(10px);
            border: 1px solid rgba(255, 255, 255, 0.18);
            box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
            color: black;
            border: 2px solid rgb(180, 180, 180);
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;

            .close-btn {
                position: absolute;
                top: 16px;
                right: 16px;
                background: transparent;
                color: #fff;
                border: none;
                font-size: 20px;

                .close {
                    font-size: 40px;
                    cursor: pointer;
                }
            }

            .images {
                width: 100%;
                height: 100%;
                border-radius: 0px;
            }
        }
    }
}