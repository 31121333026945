.container-gallery {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-auto-rows: 100px 300px;
    grid-gap: 10px;
    grid-auto-flow: dense;
    // border: 2px solid red;
    margin-bottom: 10px;

    .model {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #000;
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.4s ease, visibility .4s ease, transform 0.5s ease-in-out;
        transform: scale(0);
        overflow: hidden;
        z-index: 999;

    }

    .model.open {
        visibility: visible;
        opacity: 1;
        transform: scale(1);

        img {
            width: auto;
            max-width: 100%;
            max-height: 100%;
            height: auto;
            display: block;
            line-height: 0;
            box-sizing: border-box;
            padding: 20px 0 20px;
            margin: 0 auto;
        }

        .icon {
            position: fixed;
            top: 20px;
            right: 0px;
            width: 10px;
            // border: 2px solid red;
            height: 2rem;
            padding: 5px;
            width: 5%;
            font-size: 20px;
            background-color: rgba(0, 0, 0, 0.4);
            color: #fff;
            cursor: pointer;
        }
    }

    .gallery-container {
        .heading {
            color: white;
            font-size: 46px;
            font-weight: 1000;
            // border: 2px solid red;
            display: flex;
            height: 100%;
            justify-content: center;
            align-items: center;
        }

        .gallery-item {
            width: 100%;
            height: 100%;
            // border: 2px solid red;
            position: relative;

            .image {
                width: 100%;
                height: 100%;
                overflow: hidden;
                border-radius: 5px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    cursor: pointer;
                    transition: .5s ease-in-out;
                }
            }


            .text {
                opacity: 0;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: #fff;
                font-size: 25px;
                pointer-events: none;
                z-index: 4;
                transition: .3s ease-in-out;
                -webkit-backdrop-filter: blur(5px) saturate(1.8);
                backdrop-filter: blur(5px) saturate(1.8);
            }

            &:hover .image img {
                transform: scale(1.2);
            }

            // &:hover .text {
            //     opacity: 1;
            //     animation: move-down .3s linear;
            //     padding: 1em;
            //     width: 100%;
            // }
        }
    }

    .w-1 {
        grid-column: span 1;
    }

    .w-2 {
        grid-column: span 2;
    }

    .w-3 {
        grid-column: span 3;
    }

    .w-4 {
        grid-column: span 4;
    }

    .w-5 {
        grid-column: span 5;
    }

    .w-6 {
        grid-column: span 6;
    }

    .h-1 {
        grid-row: span 1;
    }

    .h-2 {
        grid-row: span 2;
    }

    .h-3 {
        grid-row: span 3;
    }

    .h-4 {
        grid-row: span 4;
    }

    .h-5 {
        grid-row: span 5;
    }

    .h-6 {
        grid-row: span 6;
    }

}



@media screen and (max-width:500px) {
    .container-gallery {
        grid-template-columns: repeat(1, 1fr);
    }

    .w-1,
    .w-2,
    .w-3,
    .w-4,
    .w-5,
    .w-6 {
        grid-column: span 1;
    }
}


@keyframes move-down {

    0% {
        top: 10%;
    }

    50% {
        top: 35%;
    }

    100% {
        top: 50%;
    }
}