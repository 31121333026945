.glimpses{


.title {
    margin-top: 20px;
    h1 {
      text-align: center;
      font-size: 60px;
      
      .title1 {
        color: #FF6D02;
      }
      .title2{
        color: white;
      }
    }
  
  @media screen and (max-width: 768px) {
    h1 {
        font-size: 2rem;
        margin-bottom: 1.5rem;
    }
}
  @media screen and (max-width: 576px) { 
    
    h1 {
        font-size: 2rem;
        // margin-bottom: 1.5rem;
    }
  }}


.images-gallery {
    width: 80%;
    margin: 2rem auto;
    display: flex;
    flex-wrap: wrap;
    
    h1 {
        text-align: center;
        margin-bottom: 2rem;
        font-size: 3rem;
        text-transform: capitalize;
    }
    .column img {
        width: 100%;
    }
    
    .column {
        width: 33.333333%;
        padding-right: 0.25rem;
        padding-left: 0.25rem;
    }
    
    .column div {
        margin-top: 0.25rem;
        margin-bottom: 0.25rem;
    }

    @media screen and (max-width: 576px) {
        width: 90%;
        .column {
            width: 33.333333%;
            padding-right: 0.1rem;
            padding-left: 0.1rem;
        }
        .column div {
            margin-top: 0rem;
            margin-bottom: 0rem;
        }
    }
    
    
    
   
}
}
