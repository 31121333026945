.main-nav {
    width: 100%;
    height: 70px;
    display: grid;
    background-color: rgb(0, 0, 0);
    // grid-template-columns: 100px 2fr 4fr 20px;
    position: sticky;
    top: 0px;
    left: 0px;
    // opacity: 0.9;
    z-index: 100;
    // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);

    .logo {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        width: 60px;
        margin-left: 40px;
        // border: 2px solid red;
        margin-top: 10px;

        img {
            width: 100%;
            height: 100%;
            cursor: pointer;
        }


    }

    
}


.menu-link {
    grid-column: 3/4;
}

.menu-link ul {
    height: 70px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-right: 20px;
    // border: 2px solid red;

    
}

.social-media {
    // grid-column: 4/5;
    padding-top: 20px;
    height : 70px !important
}

.social-media ul {
    height: 10rem;
    display: grid;
    grid-template-columns: 3fr repeat(3, 1fr);
    align-items: center;
    justify-content: flex-end;
}

.social-media ul li {
    text-align: right;
}

.social-media ul li:first-child {
    grid-column: 2/3;
}

/* ----------- Grid part ends ----------------- */


.menu-link ul li {
    font-size: 18px;
    font-weight: 500;
    color: white;
    list-style: none;
}

.menu-link ul li a {
    text-transform: capitalize;
    // color: rgba(0, 0, 3, 0.8);
    color: white;
    text-decoration: none;
    transition: 0.5s;
}

.menu-link ul li:hover>a {
    transform-origin: left;
    color: #FF6D02;
    cursor: pointer;
    // text-decoration: underline;
    font-weight: 500;
    transition: 0.2s;
}

.social-media ul li {
    font-size: 1.5rem;
}

.social-media .hamburger-menu {
    display: none;
}



/* responsive css style  */
@media (max-width: 1080px) {
    .main-nav {
        height: 70px;
        grid-template-columns: 2rem 2fr 3fr 2rem 2rem;
    }

    .menu-link {
        display: none;
    }

    .logo,
    .social-media ul {
        height: 7rem;
    }

    .mobile-menu-link {
        grid-column: 2/4;
        position: relative;
        z-index: 99;
    }

    .mobile-menu-link {
        padding-bottom: 20px;
        border-bottom: whitesmoke 2px solid;
        border-radius: 15px;
        background-color: black;
        display: grid;
        grid-column: 2/5;
        opacity: 0.9;
        align-items: center;
        justify-content: space-evenly;
        // padding-left: 3rem;
        transition: all 2s linear;
        transform-origin: top;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
            rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    }

    .mobile-menu-link ul {
        height: 18rem;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
        font-size: 18px;
        width: 100%;


        .special {
            display: none;
        }
    

    }

    .mobile-menu-link ul li:first-child {
        transition-delay: 0.2s;
    }

    .social-media {
        grid-row: 1/2;
        grid-column: 3/5;
        justify-items: end;
        align-items: center;
        transition: all 2s linear;
    }

    .social-media .social-media-desktop {
        height: 0;
        display: none;
    }

    .social-media {
        height: 7rem;
        display: flex;
        justify-self: end;
        align-items: center;

    }

    .social-media .hamburger-menu {
        display: block;
        font-size: 2.5rem;
        color: white;

        .icon {
            color: white;
        }
    }
}

@media (max-width: 798px) {
    .main-nav {
        height: 60px;
        grid-template-columns: 1rem 2fr 1fr 1fr 1rem;
    }

    .logo,
    .social-media ul {
        height: 6rem;
    }

    .social-media {
        height: 6rem;
        display: flex;
        justify-self: end;
        align-items: center;
    }

    .social-media .hamburger-menu {
        display: block;
        font-size: 2.5rem;
    }
}

@media (max-width: 520px) {
    .main-nav {
        height: 80px;
        grid-template-columns: 1rem 4fr 1fr 1fr 1rem;

        .logo {
            margin-left: 10px;
        }
    }

    .logo,
    .social-media ul {
        height: 6rem;
    }

    .logo h2 {
        font-size: 2rem;
    }

    .social-media {
        height: 6rem;
        display: flex;
        justify-self: end;
        align-items: center;
    }

    .social-media .hamburger-menu {
        display: block;
        font-size: 2.5rem;
    }

    /* hero section  */
}