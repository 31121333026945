.Loaderbox{
    position :fixed;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-color: rgb(0, 0, 0);
}

// .Loaderinside{
//     width: 10%;
// }
.imgdiv{
    position: absolute;
    top: 30.7vh;
    left: 50vw;
    transform: translate(-50%, -50%);
}

.loaderlogofire{
    animation: blow 1.5s ease-in-out infinite;
    transform-origin: bottom;
}


.loaderlogomashaal{
    margin: 0;
    padding: 0;
    width :100px;
}

@keyframes blow{
    0% {
        transform: translateY(2%);
        opacity: 0;
    }
     
    60%{
        transform: translateY(0%);
        opacity: 1;
    }

    100%{
        transform: translateY(2%);
        opacity: 0;
    }
    
}

.imagefader div {
    position: absolute;
    top: 40vh;
    left: 50vw;
    // width:15vw;
    transform: translate(-50%, -50%);
    animation-name: imagefade;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 0.2s;
  }
  
  @keyframes imagefade {
    0% {
      opacity:1;
    }
    17% {
      opacity:1;
    }
    25% {
      opacity:0;
    }
    92% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  .imagefader div:nth-of-type(1) {
    animation-delay: 0.15s;
  }
  .imagefader div:nth-of-type(2) {
    animation-delay: 0.1s;
  }
  .imagefader div:nth-of-type(3) {
    animation-delay: 0.05s;
  }
  .imagefader div:nth-of-type(4) {
    animation-delay: 0;
  }