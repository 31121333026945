.score-card {
    border: 2px solid white;
    height: 50%;
    width: 60%;
    margin-bottom: 45px;
    color: white;
    border-radius: 20px;
    padding-bottom: 15px;
    background-color: #090909;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;

    .part1 {
        // border: 2px solid wheat;
        height: 80%;
        width: 80%;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .college1-info,
        .college2-info {
            // border: 2px solid red;
            height: 100%;
            // width: 20;
            display: flex;
            justify-content: space-evenly;
            flex-direction: column;
            font-size: 24px;
            align-items: center;

            .name {
                margin-top: 10px;
            }

            .img {
                border: 2px solid white;
                border-radius: 50%;
                width: 150px;
                height: 150px;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 50%;
                }
            }
        }

        .vs {
            width: 160px ;
            transform: scale(0.5);

            img {
                width: 100%;
            }
        }
    }

    .part2 {
        // border: 2px solid white;
        // width: ;
        border-bottom: 2px solid white;
        margin-top: 15px;
        height: 20%;
        // margin-bottom: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        font-size: 18px;
        font-weight: 600;

        .set-details {
            margin: 5px;
            text-align: center;
        }
    }

}

@media only screen and (max-width: 450px) {
    .score-card {
        width: 90%;
        
        .part1 {
            
            // flex-direction: column;
            align-items: center;
            justify-content: space-around;
            // border: 2px solid red;
           
            width: 100%;
            margin-top: 20px;

            .college1-info,
            .college2-info {
                font-size: 18px !important;
                // border: 2px solid red;

                .img {
                    // border: 2px solid white;
                    border-radius: 50%;
                    width: 60px !important;
                    height: 60px !important;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                    }
                }
            }

            .vs {
                width: 100px ;
                transform: scale(0.5);

                img {
                    width: 100%;
                }
            }
        }
    }
}