.pointinfo {
    border: 2px solid white;
    height: 80px;
    display: flex;
    justify-content: space-around;
    border-radius: 5px;
    align-items: center;
    margin: 10px;
    width: 80%;
    text-transform: uppercase;
    margin-top: 20px;

    .serial-no {
        width: 10%;
        margin-left: 20px;
        // border: 2px solid wheat;
    }

    .category {

        width: 30%;
    }
    .vs{
        margin-right: 20px;
    }

    .opponent {

        // border: 2px solid wheat;
        width: 20%;

        .name {
            margin-left: 5px;
        }
    }

    .point {
        width: 20%;
        // border: 2px solid wheat;

    }

    .sportname {
        width: 30%;
    }

    .serial-no,
    .category,
    .opponent,
    .point,
    .sportname {

        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;

    }

}

@media screen and (max-width:750px) {
    .pointinfo {
        width: 98%;
        height: 60px;
        margin: 5px 5px;
        // text-transform: lowercase;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .serial-no {
            width: 10%;
            margin-left: 10px;
            // border: 2px solid wheat;
        }

        .category {

            width: 20%;
        }

        .opponent {

            // border: 2px solid wheat;
            width: 20%;

            .name {
                margin-left: 5px;
            }
        }

        .point {
            width: 20%;
            // border: 2px solid wheat;

        }

        .sportname {
            width: 30%;
        }

        .serial-no,
        .category,
        .opponent,
        .point,
        .sportname {

            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
        }

    }
}

@media screen and (max-width:350px) {
    .pointinfo {
        // margin: 10px;
        height: 50px !important;
        // text-transform: lowercase;
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 95%;
        margin: 5px 5px;

        .serial-no {
            width: 10%;
            margin-left: 2px;
        }

        .category {

            width: 20%;
        }

        .opponent {

            // border: 2px solid wheat;
            width: 30%;

            .name {
                margin-left: 5px;
            }
        }

        .point {
            width: 20%;
            // border: 2px solid wheat;

        }

        .sportname {
            width: 20%;
        }

        .serial-no,
        .category,
        .opponent,
        .point,
        .sportname {

            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
        }

    }
}