
.AboutUs {
  width: 100%;
  position: relative;
  
 
  
  .content {
    // width: 100%;
    padding: 10px;
    margin-top: 50px;
    color: #fff;
    
    .title {
      margin-top: 20px;
      h1 {
        text-align: center;
        font-size: 60px;
        
        .title1 {
          color: #FF6D02;
        }
      }
    }
  
    .main-body{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .logos{
      margin-top: 50px;
    }
    
    
    .description {
      color: rgb(240, 240, 240);
      text-align: center;
      font-size: 1.4rem;
      line-height: 1.5;
      // background-color: #151515;
      opacity: 0.9;
      padding: 10px;
      // border-radius: 40px;
      margin: 50px;
      margin-top: 0px;
      display: flex;
      flex-direction: column;
      margin-bottom: 50px 40px;
      justify-content: center;
      align-items: center;
      width: 80%;
      // box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
      transition: transform 0.3s ease;
    }
    
    // .description:hover {
    //   transform: scale(1.05);
    // }
  }
  .merchbox{
    width: full;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 90px;
    gap: 0;
    // margin-top: 0px;

    .buttondiv{
      display: flex;
      justify-content: center;
      align-items: center;
      

      .btn-primary{
        text-decoration: none;
        font-size: 1.3rem;
        color: #FF6D02;
        padding: 10px 20px;
        // background: #FF6D02;
        border: #FF6D02 2px solid;
        border-radius: 14px;
        transition: all 0.3s ease-in;
      }
      .btn-primary:hover{
        color : white;
        background: #FF6D02;
      }
    }

  }
  
  
  .parallax__layer--base {
    img {
      width: 100%;
      height: 100%;
      // background-size: contain;
    }
  }
  
  .para {
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 10px;
    width: auto;
    text-align: center;
    justify-content: center;
  }
  
  .main-body {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .icons {
    margin: 0px 30px;
    font-size: 44px;
    cursor: pointer;
    transition: transform 0.3s ease, color 0.3s ease;
    
    &.instagram {
      color: #d11cd1;
    }
    
    &.facebook {
      color: rgb(26, 72, 158);
    }
    
    &.linkedin {
      color: rgb(19, 82, 170);
    }
  }
  
  .icons:hover {
    transform: scale(1.25);
    filter: brightness(0.85);
  }
  
  .social-media {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 20px;
    height: 80px;
  }
  
  .icons {
    margin: 0 30px;
    font-size: 44px;
    color: #fff;
    cursor: pointer;
    border: 2px solid white;
    border-radius: 50%;
    width: 80px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease, color 0.3s ease;
  }
  
  .icons:hover {
    transform: scale(1.2);
    color: #FF6D02;
  }
  
  .ath {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-bottom: 10px;
    // border: 2px solid red;
    
    img {
      width: 60%;
      // border: 2px solid red;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  
  
  
  .logos {
    display: flex;
    justify-content: space-around;
    margin-top: 150px;
    
    .logo {
      max-width: 16%;
      height: auto;
    }
  }
  
  @media only screen and (max-width: 960px) {
    

    .logos {
      margin-top: 100px;
      
      .logo {
        max-width: 23%;
      }
    }
    
    .social-media {
      width: 100%;
    }
    .content{
      .title {
        h1 {
          text-align: center;
          font-size: 40px;
          
          .title1 {
            color: #FF6D02;
          }
        }
      }
    }
    .merchbox{
      .buttondiv{
        .btn-primary{
          padding: 8px 12px;
          border-radius: 10px;
          font-size: 1.2rem ;
        }
  
      }
    }
    .icons {
      font-size: 36px;
      margin: 0 10px;
      height: 60px;
    }
    
    .ath {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  
  
  @media only screen and (max-width: 450px) {
    .logos {
      margin-top: 80px;
      
      .logo {
        max-width: 30%;
      }
    }
    
    .icons {
      margin-top: -30px;
      margin-bottom: 0px;
    }
    
    .content {
      
      padding: 5px;
      
      .description {
        
        font-size: 1.2rem;
        line-height: 1.5;
        // background-color: #151515;
        opacity: 0.9;
        padding: 15px;
        border-radius: 30px;
        margin: 30px;
        margin-top: 0px;
        display: flex;
        flex-direction: column;
        margin-bottom: 50px;
        justify-content: center;
        align-items: center;
        width: 90%;
        // box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
        transition: transform 0.3s ease;

        
      }
      
      .title {
        h1 {
          text-align: center;
          font-size: 30px;
          
          .title1 {
            color: #FF6D02;
          }
        }
      }
    }
    
    margin-top: -10px;
    margin-bottom: 0px;
  }
}


