// Fire Theme Variables (Simplified)
$primary-color: #ff4500;  // Bright orange-red
$secondary-color: #ffffff;  // Deep orange
$background-color: #1c1c1c;  // Dark background
$button-color: #ff6347;  // Tomato color for buttons
$text-color: #ffffff;  // White text
$hover-color: #ffae42;  // Lighter orange for hover effects

// Mixin for button styling
@mixin button-style {
  background-color: $primary-color;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  color: $text-color;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: $hover-color;
  }

  &:focus {
    outline: none;
    background-color: $hover-color;
  }
}

// Admin Home Page styling
.adminhomepage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh; // Full viewport height to center vertically
  min-width: 100vw;
//   background-color: $background-color;
  padding: 40px;
  border-radius: 10px;
//   box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  color: $text-color;

  // Inner container for the buttons
  div {
    text-align: center;
  }

  // Style for the buttons
  a {
    @include button-style;
    margin: 10px;
    width: 200px; // Set a fixed width for consistency
  }

  button {
    @include button-style;
    margin-top: 20px;
    width: 200px;
  }

  // Responsive styling
  @media (min-width: 768px) { // Medium screens
    a, button {
      width: 150px; // Slightly narrower on medium screens
    }
  }

  @media (min-width: 1200px) { // Larger screens
    width: 50vw; // Make the container 50% of the viewport width
  }

  // Not logged-in state styling
  div:last-child {
    color: $secondary-color;
    font-weight: bold;
  }
}
