.score-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    // background-color: #151515;
    // border: 2px solid red;

    .live-score {
        // background-color: #151515;
        height: 100%;
        color: #141E46;
        text-transform: uppercase;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 40px;

        .sport-name {
            // border: 2px solid red;
            height: 90px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;
            font-weight: 800;
            color: white;
            // border: 2px solid white;

            .name {
                font-size: 48px;
                // color: #141E46;
                .live{
                    width: 80px;
                    animation: pulse 1.5s infinite;
                }
            }

            .img {
                width: 80px;
                height: 80px;
                margin-left: 30px;
                transition: liveAnime 0.5s linear infinite ease;

                @keyframes liveAnime {
                    0% {
                        opacity: 0;
                    }

                    50% {
                        opacity: 1;
                    }

                    100% {
                        opacity: 0;
                    }
                }

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .score-content {
            // border: 10px solid red;
            display: flex;
            height: 90%;
            justify-content: flex-end;
            align-items: center;
            flex-direction: column-reverse;
        }
    }
}

@media only screen and (max-width: 450px) {
    .score-page {
        .live-score {
            .sport-name {
                .name {
                    font-size: 36px;
                }
            }
        }
    }
}

@keyframes pulse {
    0% {
      transform: scale(1.05);
      opacity: 1;
    }
    50% {
      transform: scale(1); // Increase the scale slightly
      opacity: 0.5;           // Reduce opacity for the pulse effect
    }
    100% {
      transform: scale(1.05);
      opacity: 1;
    }
  }